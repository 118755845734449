
.root{
background-color: #e1ad01 ;
}
.paper{
    padding: 50px;
    min-height: 90vh;
}
.papers{
    padding: 50px;
}

