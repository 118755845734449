
.score-content {
  padding-top: 10px;
  /* background-color: #fff; */
}
.score-div{
  font-size: 2em !important;
  /* padding-bottom: 40px; */
}
.pass-mark{
  margin-right: 10px;
  font-size: 20px;
}

