@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body{
  /* background-color: #414141; */
  /* background-color: #166d3b;
  background-image: linear-gradient(147deg, #166d3b 0%, #000000 74%); */
  background-color: rgb(247, 244, 244);
  min-height: 100vh;
  font-size: 12px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* container here */
#container{
  background-color: white;
  width: 400px;
  height: 580px;
  box-shadow: 0 2px 2px 2px white;
  opacity: 0.9;
  border-radius: 15px;
}

#forgot_container{
  background-color: white;
  width: 400px;
  height: 320px;
  box-shadow: 0 2px 2px 2px white;
  opacity: 0.9;
  border-radius: 15px;
}
#forgot_container h3 {
  text-align: center;
}
#reg_container{
  background-color: white;
  color: black;
  width: 350px;
  padding: 20px 0;
  height: 100%;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: 0;
}

.reg_form, .reg_submit{
  margin-top: 30px;
}
.reg_submit div{
  padding: 0 15px;
}

.err-text {
  color: red;
}
.options {
  background-color: #ffffff;
  /* border: 1px solid #28a745; */
  margin: 15px 0;
  padding: 5px 15px;
  text-align: center;
  border-radius: 0.5px;
  box-shadow: 0 2px 6px 3px #d4d4d4;
  cursor: pointer;
}

.option{
  margin: 5px 0;
  padding: 1px 15px;
}

.options:hover {
  background: #ffc400;
}
.selected {
  background: #f44336 !important;
  
}
.navs {
  border: 1px solid rgb(128, 128, 128);
  box-shadow: 0 2px 2px 4px;

}
/* .test {
  padding: 30px;
  text-align: center;
  color: white;
  width: 100%;
  margin: auto;
  box-shadow: 0px 0px 5px 0px rgb(2, 175, 2);
}
.test h2 {
  font-size: 60px;
} */

.timer  {
 /* position: absolute;
left: 5%; */
margin-bottom: 50px;
padding: 20px;
}
.timer span{
  font-size: 25px;
  font-weight: bolder;
  color: red;
  font-family:'helvetica';
  padding: 30px 15px;
  border-radius: 2%;
  background: rgb(190, 189, 189);
  border-radius: 50%;
}

.icon-bgd {
  margin-top: 5px;
  display: flex;
  flex: left 1;
  padding: 0;
}
footer {
  position: fixed;
  bottom: -10px;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
footer p {
  display: inline-block;
  margin: 0 auto;
}
footer p:nth-child(2){
  padding-left: 30px;
}

.color{
  color: #f9f9f9 !important;
  font-weight: bold;
}

.foot-txt {
padding-top: 20px;
}
.foot-txt h3 {
   text-transform: uppercase;
   
}
.foot-txt p {
   color: #fff;
}
.cards {
   background-color: #e6e6e6;
   width: 100%;
   padding: 30px ;
}
.cards p {
  margin-top: 20px;
}

.signup-text{
  text-align: center;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-top: 10px;
}

#btnId{
  margin-top: 30px;
 /* width: 100%; */
 margin: 0 auto;
}

#start{
  /* width: 100%; */
  margin-top: 20px;
  font-size: 15px;
  border: none;
}


#tags{
margin-top: 10px;
}

#label{
  font-size: 14px;
  font-family: Helvetica, sans-serif;
}

.extra{
  padding-top: 10px;
  padding-bottom: 20px;
}

 .register, #container,  #forgot_container, #spinner{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.login_form{
  padding: 60px 10px 30px 10px;
}

.login_hold {
  margin-top: 15px;
}
.instruction {
background-color: white;
width: 95%;
padding: 30px 30px;
margin: 0  auto 50px auto;
}
.instructions-hold{
padding: 10% 0;
}

.instruction h2{
  /* font-weight: 700; */
  color: #00a004;
  font-size: 4pc;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 50px;
}

.instruction a{
  color: #c5912e;
  font-weight: 800;
}

.instruction li{
  padding-bottom: 10px;
  font-size: 1.2em;
}
.instruction p {
  font-size: 1.1em;
}

#question{
  font-size: 16px;
  font-weight: bold;
}

.proceed{
  margin-top: 15px;
  background-image: linear-gradient(to right, #75cc03 0%, #03aa11 51%, rgb(2, 146, 26) 100%);
  color: #ffffff;
  width: 20%;
  font-size: 16px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.proceed:hover {
  background-image: linear-gradient(to right, #fdb633 0%, rgb(252, 152, 2) 51%, #F09819 100%);
  color: #ffffff;
  width: 20%;
  font-size: 16px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
 }
 /* .home {
   width: 50%;
   padding: 200px 0;
 } */
 .ndpr {
   display: inline;
 }
.question {
  margin-top: 100px;
}

#unselectable { 
  -webkit-user-select: none; 
  -webkit-touch-callout: none; 
  user-select: none;    
  color: black;
  font-size: 16px;
  margin: 35px 0;
  
  } 
input {
  background: white;
}
.icon_user {
  text-align: center;
  padding: 10px 0;
}
.score_table {
  margin-top: 70px;
}
.passmark {
  text-align: right;
  color: #00a004;
}
.examinee {
  text-transform: capitalize;
}
.result {
  
  text-align: right;
  margin-bottom: 50px;
}
.score {
  background: #00a004;
  border-radius: 50%;
  font-size: 50px;
  padding: 25px 10px;
}
.forgot {
  text-align: right;
 margin-top: 5px;
 margin-bottom: 10px;
 padding: 0 20px;
}
.forgot a {
  color: black;
}

.fixed_header{
  width: 400px;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody{
overflow: auto;
height: 100px;
}

.fixed_header thead {
background: black;
color:#fff;
}

.fixed_header th, .fixed_header td {
padding: 5px;
text-align: left;
width: 200px;
}
.modal {
  top: 50%;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, -50%);
}

.modal h3, .modal h4{
  line-height: 3;
  color: #00a004;
  text-transform: uppercase
  }

.test-countdown h2 {
  text-align: center;
  font-size: 50px;
}
.test-countdown  {
  position: absolute;
  left: 0;
  width: 200px;
  padding: 60px 50px;
  background: white;
  -webkit-animation: test-countdown 0.5s forwards;
  -webkit-animation-delay: 5s;
  animation: test-countdown 5s forwards;
  animation-delay: 2s;
}
.exportBtn {
  margin-top: 15px;
}
.scores {
  padding-bottom: 100px;
}

@keyframes test-countdown {
  100% { left: 40%; }
}

  
@media (max-width: 800px) {
  .test h2 {
    font-size: 50px;
  }

  #container{
    width: 350px;
    height: 500px;
  }
  .instruction {
    width: 95%;
    padding: 30px 10px 50px 10px;
    text-align: start;
    }
}

@media (max-width: 600px) {
  .test {
    padding: 0;
    box-shadow: none;  
  }
  .body{
    min-height: 120vh;
  }
  .test h2 {
    font-size: 35px;
  }
  #container{
    margin-left: 40px;
    width: 250px;
    height: 410px;
  }
  .login_form{
    padding: 30px 10px;
  }
  .login_hold {
    margin-top: 5px;
  }
  .instruction h2{
    font-size: 1.3em;
  }
  .instruction li{
    font-size: .9em;
  }
  .instruction p {
    font-size: 1em;
  }
  .instruction {
    width: 99%;
  }
  .forgot {
   margin-top: 5px;
  
  }
}

.main-content {
    position: absolute;
   width: 100%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    min-height: 100vh;
    border-radius: 0.3%;
    background:
    linear-gradient(to bottom, #f8f7f7d0 , #0a0a0ad0 ),
    url(/static/media/ndpr.bff2b51b.jpg)no-repeat center center;
      background-size: cover; 
      /* min-height: 100vh;   */
}

.text-fields{
    padding: 10px 0;
}
.btns{
    margin: 0 auto;
    padding: 25px 0 10px 0;
}
.form-hold{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    padding: 0 20px;
}

li{
    padding: 20px 0;
}



.root{
background-color: #e1ad01 ;
}
.paper{
    padding: 50px;
    min-height: 90vh;
}
.papers{
    padding: 50px;
}



.users-div{
    margin: 50px 50px;
}
.radio-group {
  margin-left: 30px;
}

.radio-group div {
  margin-top: 5px;
}


.score-content {
  padding-top: 10px;
  /* background-color: #fff; */
}
.score-div{
  font-size: 2em !important;
  /* padding-bottom: 40px; */
}
.pass-mark{
  margin-right: 10px;
  font-size: 20px;
}


.footer-text {
padding: 10px;
text-align: center;
font-size: 14px;
color: #000;
}
.privacy-text {
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

@media (max-width: 500px) {
    .footer-text {
      padding-left: 45px;
      font-size: 12px;
    }
    .privacy-text {
        font-size: 12px;
        padding-right: 20px;
    }
}
