.footer-text {
padding: 10px;
text-align: center;
font-size: 14px;
color: #000;
}
.privacy-text {
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

@media (max-width: 500px) {
    .footer-text {
      padding-left: 45px;
      font-size: 12px;
    }
    .privacy-text {
        font-size: 12px;
        padding-right: 20px;
    }
}