.main-content {
    position: absolute;
   width: 100%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    min-height: 100vh;
    border-radius: 0.3%;
    background:
    linear-gradient(to bottom, #f8f7f7d0 , #0a0a0ad0 ),
    url(../../assets/ndpr.jpg)no-repeat center center ;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; 
      /* min-height: 100vh;   */
}

.text-fields{
    padding: 10px 0;
}
.btns{
    margin: 0 auto;
    padding: 25px 0 10px 0;
}
.form-hold{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    padding: 0 20px;
}